import React from "react"
import styled from "@emotion/styled"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { Link as GatsbyLink } from "gatsby"

const LinkStyles = () => `
	font-size: inherit;
	text-decoration: none;
	cursor: pointer;
`

const StyledLinkElement = styled.a`
  ${({ setTheme }) => `
		${LinkStyles(setTheme)}
	`}
`

const StyledGatsbyLink = styled.div`
  ${({ theme }) => `
		${LinkStyles(theme)}
	`}
`

const Link = ({
  to,
  external,
  target,
  children,
  label,
  className,
  setTheme,
  title,
  name,
}) => {
  let asElement = GatsbyLink
  if (!external && to && to.includes("#")) {
    asElement = AnchorLink
  }

  if (external) {
    return (
      <StyledLinkElement
        title={title}
        name={name || title}
        aria-label={name || title}
        className={className}
        href={to}
        target={target}
        theme={setTheme}
        rel="noopener noreferrer"
      >
        {children || label}
      </StyledLinkElement>
    )
  } else {
    return (
      <StyledGatsbyLink
        title={title}
        name={name || title}
        aria-label={name || title}
        className={className}
        to={to}
        theme={setTheme}
        as={asElement}
      >
        {children || label}
      </StyledGatsbyLink>
    )
  }
}

Link.defaultProps = {
  external: false,
  target: "",
  setTheme: "alert",
}

export default Link
